// Messages (fr)

const config = {
  code: 'fr',
  label: 'Français',
  label_en: 'French',
  rtl: false,
};

const messages = {
  // Navbar
  navbar_title: 'Se désabonner',

  // Unsubscribe page
  unsubpage_title: 'Etes-vous sûr de vouloir vous désabonner?',
  unsubpage_msg1:
    'Si vous ne souhaitez plus recevoir de communications de notre part, veuillez cliquer sur le bouton de désinscription ci-dessous pour confirmer.',
  unsubpage_unsubscribe: 'Se désabonner',
  unsubpage_unsubscribing: 'En cours...',
  unsubpage_result_success: 'Vous avez été désabonné.',
  unsubpage_result_error: "Échec de l'opération.",

  // Error page
  errorpage_title: 'Oops!',
  errorpage_msg1: 'Ce lien de désinscription est invalide.',
  errorpage_msg2: "Nous n'avons pas pu poursuivre.",
};

const lang = { config, messages };

export default lang;
